import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getProfile, stipeLink } from "../../services/AuthService";
import dp from "../../images/blank-profile-picture.webp";
import EditProfile from "../modal/EditProfile";
import Spinner from "../common/Spinner";
import { notifyError } from "../common/Toaster";
import ButtonLoader from "../common/ButtonLoader";
export default function Profile() {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);

  async function getProfileData() {
    try {
      setLoader(true);
      const response = await getProfile();
      setData(response.data?.data);
      localStorage.setItem("profilePic", JSON.stringify(response.data?.data?.profilePicture));
    } catch (error) {
      console.log(error?.response, "heloo");
    } finally {
      setLoader(false);
    }
  }
  async function onContinue() {
    try {
      const response = await stipeLink();
      console.log(response.data.data, " response");
      window.open(response?.data?.data?.url, "_self");
    } catch (error) {
      notifyError(error.response.data.message);
      console.log(error, "error");
    }
  }
  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <div>
      <div className="page-titles">
        <h4>Profile</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="#">Profile</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Profile</Link>
          </li>
        </ol>
      </div>

      <Card style={{ width: "70%" }} className="w-100">
        {loader ? (
          <Spinner />
        ) : (
          <Card.Header className="d-block">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "1rem" }}
            >
              <div>
                <img
                  src={"https://staging-api.bookwineries.com/" + data?.profilePicture}
                  width={100}
                  height={100}
                  className="text-center rounded"
                />
              </div>
              <div className="text-left"> 
                <h2 className="text-black ">{data?.wineryName}</h2>
                <h3 className="text-black ">{data?.name}</h3>
                <p className="text-black mb-0">{data?.email}</p>
                <p className="text-black mb-0">{data?.contact}</p>
                <p className="text-black mb-0">
                  Commission : {data?.vendorCommision} %
                </p>
                {data?.isVerified ? (
                  <p className="text-success mb-0">{"Verified"}</p>
                ) : (
                  <p className="text-danger mb-0">{"Not Verified"}</p>
                )}
                {data?.stripeForm ? (
                  <p className="text-success mb-0">{"Stripe Account Connected"}</p>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary px-3 py-2 fs-12 mt-3"
                    onClick={onContinue}
                    disabled={loader}
                  >
                    
                    {loader ? (
                      <>
                        <ButtonLoader /> Stripe connect
                      </>
                    ) : (
                      "Stripe connect"
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="mt-5 ">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => setShowModal(true)}
              >
                Edit Profile
              </button>
            </div>
          </Card.Header>
        )}
      </Card>

      {showModal && (
        <EditProfile
          show={showModal}
          data={data}
          profile={getProfileData}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
