import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { editProfile } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { Modal } from "react-bootstrap";
import { set } from "date-fns";

export default function EditProfile({ show, data, profile, onHide }) {
  const [file, setFile] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    wineryName: "",
    contact: "",
    countryCode: "+357",
  });
  const [errors, setErrors] = useState({
    name: "",
    wineryName: "",
    contact: "",
    countryCode: "",
  });
  async function onUpdate(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errors };

    for (const field in formData) {
      if (formData[field].trim() === "") {
        errorObj[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
        error = true;
      }
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const formdataValue = new FormData();
      formdataValue.append("name", formData.name);
      formdataValue.append("wineryName", formData.wineryName);
      formdataValue.append("contact", formData.contact);
      formdataValue.append("countryCode", formData.countryCode);
      if (file) {
        formdataValue.append("picture", file);
      }
      const response = await editProfile(formdataValue);
      onHide();
      profile();
      console.log(response.data.data, " response");
      notifyTopRight(response.data?.message);
    } catch (error) {
      notifyError(error.response.data.message);
      console.log(error, "error");
    }
  }
  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name,
        contact: data.contact,
        countryCode: "+" + data.countryCode || "+357",
        wineryName: data?.wineryName,
      });
    }
  }, [data]);

  return (
    <div>
      <Modal className="modal fade" show={show} centered onHide={onHide}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onUpdate}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Profile</h4>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.name}
                        onChange={(e) => {
                          setFormData({ ...formData, name: e.target.value });
                          setErrors({ ...errors, name: "" });
                        }}
                        placeholder="Name"
                      />
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Winery Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.wineryName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            wineryName: e.target.value,
                          });
                          setErrors({ ...errors, wineryName: "" });
                        }}
                        placeholder="Winery Name"
                      />
                      {errors.wineryName && (
                        <div className="text-danger fs-12">
                          {errors.wineryName}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Phone Number</strong>
                      </label>
                      <div className="contact-name d-flex justify-content-start">
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          value={formData.countryCode}
                          onChange={(e) =>
                            setFormData({ ...formData, countryCode: e })
                          }
                          // inputStyle={{ backgroundColor: "transparent" }}
                          disable={true}
                          inputProps={{
                            readOnly: true, // Disable manual typing
                          }}
                        />
                        <input
                          type="tel"
                          name="contact"
                          className="form-control extra-padding"
                          value={formData.contact}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              contact: e.target.value,
                            });
                            setErrors({ ...errors, contact: "" });
                          }}
                          pattern="[0-9]*"
                          onInput={(e) =>
                            (e.target.value = e.target.value.replace(/\D/, ""))
                          }
                        />
                      </div>
                      {errors.contact && (
                        <div className="text-danger fs-12">
                          {errors.contact}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Image</strong>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        placeholder="Enter title"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  // onClick={onChangePassword}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-light"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
